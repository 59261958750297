import {blue, blueGrey, green, grey, indigo, lightGreen} from "./palette"

const Colors = {
  listItemColor: "rgba(184, 199, 211, 0.3)",
  primary: "#82b1cb",
  "primary-light": "#9fc9e6",
  "primary-dark": "#7eadc7",
  error: "#d32f2f",
  warning: "#ff9800",

  white: "#fff",
  black: "#000",
  blue,
  blueGrey,
  green,
  grey,
  indigo,
  lightGreen
}

const ThemeColors = {
  text: grey[800],
  "text-light": grey[600],
  "text-disabled": "rgba(0, 0, 0, 0.38)",
  "blue-light": blue[200],
  "blue-dark": blue[400],
  "grey-light": grey[200],
  "grey-dark": grey[500],
  "separator-grey": grey[400],
  navbar: grey[100],
  border: "#bdbdbd",
  "border-hover": "#9e9e9e",
  divider: "#e0e0e0",
  link: Colors.primary,
  iconLink: blue[800],
  facebook: "#4267B2",
  twitter: "#2AAAE5",
  instagram: "#D82E7A"
}

export default {...Colors, ...ThemeColors}
