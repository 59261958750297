import {
  setDrawerContent,
  setFocusedElement,
  setMobileFooterContent,
  setModalContent,
  setShowingDrawer,
  setShowingModal,
  setViewportWidth
} from "redux/actions"
import {getBreakpointFromViewportWidth} from "lib/utils/helpers"

const initialState = {
  breakpoint: 3,
  drawerContent: null,
  focusedElement: null,
  isMobile: false,
  // a react component
  modalContent: null,
  showingDrawer: false,
  showingModal: false,
  viewportWidth: -1
}

function uiReducer(state = initialState, action) {
  switch (action.type) {
    case setDrawerContent:
      return Object.assign({}, state, {
        drawerContent: action.value
      })
    case setFocusedElement:
      return Object.assign({}, state, {
        focusedElement: action.value
      })
    case setMobileFooterContent:
      return Object.assign({}, state, {
        mobileFooterContent: action.value
      })
    case setModalContent:
      return Object.assign({}, state, {
        modalContent: action.value
      })
    case setShowingDrawer:
      return Object.assign({}, state, {
        showingDrawer: action.value
      })
    case setShowingModal:
      return Object.assign({}, state, {
        showingModal: action.value
      })
    case setViewportWidth:
      return Object.assign({}, state, {
        breakpoint: getBreakpointFromViewportWidth(action.value),
        viewportWidth: action.value
      })
    default:
      break
  }
  return state
}

export default uiReducer
