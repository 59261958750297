import {fontFamilyBody, fontFamilyHeader} from "lib/config"

import colors from "../colors"

export default {
  htmlFontSize: 16,
  fontFamily: fontFamilyBody,
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightBold: 600,
  paddingTop: "3px",
  h1: {
    fontFamily: fontFamilyHeader,
    fontWeight: 400,
    fontSize: "42px",
    lineHeight: 1,
    letterSpacing: -1.5
  },
  h2: {
    fontFamily: fontFamilyHeader,
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "35px",
    letterSpacing: 0
  },
  h3: {
    fontFamily: fontFamilyHeader,
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: 1.04,
    letterSpacing: 0.3
  },
  h4: {
    fontFamily: fontFamilyHeader,
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "25px",
    letterSpacing: 0.3
  },
  h5: {
    fontFamily: fontFamilyHeader,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: 0.5
  },
  h6: {
    fontFamily: fontFamilyHeader,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.43,
    letterSpacing: 0.25
  },
  body1: {
    fontFamily: fontFamilyBody,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: 0.5
  },
  body2: {
    fontFamily: fontFamilyBody,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: 1.43,
    letterSpacing: 0.25
  },
  subtitle1: {
    color: colors["text-light"],
    fontFamily: fontFamilyBody,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: 1.75,
    letterSpacing: 0.15
  },
  subtitle2: {
    color: colors["text-light"],
    fontFamily: fontFamilyBody,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.57,
    letterSpacing: 0.1
  },
  button: {
    fontFamily: fontFamilyBody,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 1.75,
    letterSpacing: 1.25,
    textTransform: "uppercase"
  }
}
