import {addIndex, always, cond, is, isEmpty, isNil, gt, map, T} from "ramda"
import {breakpoints} from "styles/theme"

export const hasData = data => {
  if (isNil(data)) {
    return false
  }
  if (is(Array, data) || is(Object, data)) {
    return !isEmpty(data)
  }
  if (is(String, data)) {
    return data.length > 0
  }
  return true
}

export const mapIndexed = addIndex(map)

export const getBreakpointFromViewportWidth = viewportWidth =>
  cond([
    [isNil, always(2)],
    [gt(breakpoints[1]), always(0)],
    [gt(breakpoints[2]), always(1)],
    [gt(breakpoints[3]), always(2)],
    [gt(breakpoints[4]), always(3)],
    [gt(breakpoints[5]), always(4)],
    [T, always(5)]
  ])(viewportWidth)
